import { onDelete } from "./index";

const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
      code
      statusCode
      materialID
      partID
      salesID
      buyerID
      vendorID
      customerID
      price
      currency
      leadtime
      validDays
      incoTerms
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      createdAt
      updatedAt
    }
  }
`;

export default async function onDeleteOffer({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }
}

async function deleteSingleItem(item, client) {
  // console.log({ item, client });
  const { id } = item || {};

  const { data } = await onDelete({
    input: { id },
    mutation: deleteOffer,
    // updateCacheFields: ["listParts"],
    client,
  });
}
