import { onUpdate } from "./index";

const getCustomerVendorRelation = /* GraphQL */ `
  query GetCustomerVendorRelation($id: ID!) {
    getCustomerVendorRelation(id: $id) {
      id
      alias
      vendorCode
      statusCode
      vendorID
      vendor {
        id
        name
        groups
        incoTerms
        paymentTerms
        createdAt
        updatedAt
      }
      groups
    }
  }
`;
const updateCustomerVendorRelation = /* GraphQL */ `
  mutation UpdateCustomerVendorRelation(
    $input: UpdateCustomerVendorRelationInput!
    $condition: ModelCustomerVendorRelationConditionInput
  ) {
    updateCustomerVendorRelation(input: $input, condition: $condition) {
      id
      alias
      customerCode
      vendorCode
      statusCode
      customerID
      customer {
        id
        name
        alias
        groups
        incoTerms
        paymentTerms
        createdAt
        updatedAt
      }
      vendorID
      vendor {
        id
        name
        alias
        groups
        incoTerms
        paymentTerms
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;

export default async function onUpdateSupplier({ values, client }) {
  // const { id, name, vendorID, alias, statusCode, vendorCode } = values;

  const fieldsToUpdate = [
    { field: "vendorCode", createUpdate: true },
    { field: "alias", createUpdate: true },
    { field: "statusCode", createUpdate: true },
  ];

  await onUpdate({
    type: "CustomerVendorRelation",
    values,
    mutation: updateCustomerVendorRelation,
    query: getCustomerVendorRelation,
    fieldsToUpdate,
    client,
    refetchQueries: [],
  });

  // const inputforUpdateEntity = {
  //   id: vendorID,
  //   name:name.trim(),
  // };
  // const inputforUpdateRelation = {
  //   id,
  //   vendorCode:vendorCode.trim(),
  //   statusCode,
  //   alias:alias.trim(),
  // };

  // await onUpdate({
  //   input: inputforUpdateEntity,
  //   mutation: updateEntity,
  //   client,
  // });

  // await onUpdate({
  //   input: inputforUpdateRelation,
  //   mutation: updateCustomerVendorRelation,
  //   client,
  // });
}
